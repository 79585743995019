import React from 'react';
import axios, { AxiosError } from 'axios';
import { csrfToken } from '../../common/phxSecurity';

interface Props {
  url: string;
  attributes?: any;
}

interface State {
  embed_url: string | null;
  error: string | null;
}

export class Embed extends React.Component<Props, State> {
  servicePath = "/sigma/embeds";

  constructor(props: Props) {
    super(props);
    this.state = { embed_url: null, error: null };
  }

  componentDidMount(): void {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken();
    axios.post(this.servicePath, { embed: { url: this.props.url, attributes: (this.props.attributes || {}) } }, { headers: { 'Accept': 'application/json' } })
      .then((response) => {
        this.setState({ embed_url: response.data.url })
      })
      .catch((error: AxiosError) => {
        this.setState({ error: "Server encountered an error.  Please try again later." });
      });
  }

  renderError = () => {
    if (!this.state.error) return;
    return <div className="alert alert-danger">{this.state.error}</div>
  }

  render() {
    if (this.state.error)
      return this.renderError();

    if (this.state.embed_url)
      return (
        <div className="embed-responsive embed-responsive-16by9">
          <iframe title="Sigma Embed" id="sigmaDashboard" frameBorder="0" src={this.state.embed_url} className="embed-responsive-item"></iframe>
        </div>
      );

    return <div></div>;
  }
}

export default Embed;
