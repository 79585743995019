import React from 'react';
import Spinner from '../shared/Spinner';
import { ApiService } from '../../services/ApiService';
import { AxiosError, AxiosResponse } from 'axios';
import DataError from '../shared/DataError';
import moment from 'moment'

interface Props {
  data_date_end: string
}

interface State {
  data: Response
  loaded: boolean
  error: any
}

interface Response {
  data_dates: string[]
  groups: Group[]
  values: { [remote_rate_name: string]: { [data_date: string]: number } }
}

interface Group {
  name: string
  rates: Rate[]
}

interface Rate {
  name: string
  remote_name: string
}

export class MarketRateParRatesAndYieldsTable extends React.Component<Props, State> {
  static dateFormatter = new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });

  constructor(props: Props) {
    super(props);
    this.state = { loaded: false, data: { data_dates: [], groups: [], values: {} }, error: null }
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/market_rates/par_rates_and_yields`, { data_date_end: this.props.data_date_end })
      .then((response: AxiosResponse) => {
        this.setState({
          data: response.data,
          loaded: true
        });
      })
      .catch((error: AxiosError) => {
        this.setState({ loaded: true, error: error });
      })
  }

  render(){
    return this.state.loaded ? this.renderTable() : this.renderLoading();
  }

  private formatDateString = (dateString: string): string => {
    return MarketRateParRatesAndYieldsTable.dateFormatter.format(moment(dateString, "YYYY-MM-DD"));
  }

  private renderLoading = () => {
    return <Spinner width="3rem" height="3rem" />;
  }

  private renderTable = () => {
    if (this.state.error) { return <DataError />; }

    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th></th>
            {this.state.data.data_dates.map((dataDate) => {
              return <th key={dataDate} className="text-right">{this.formatDateString(dataDate)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {this.state.data.groups.map((group, groupIndex) => {
            let groupRows = group.rates.map((rate) => {
              return (
                <tr key={rate.name}>
                  <td>{rate.name}</td>
                  {this.state.data.data_dates.map((dataDate) => {
                    return <td key={rate.name + '-' + dataDate} className="text-right">
                      { this.state.data.values[rate.remote_name][dataDate]?.toFixed(3) }
                    </td>;
                  })}
                </tr>
              );
            })
            groupRows.push(<tr key={"group-" + groupIndex}><td colSpan={this.state.data.data_dates.length + 1}></td></tr>);
            return groupRows
          })}
        </tbody>
      </table>
    );
  }
}

export default MarketRateParRatesAndYieldsTable;
