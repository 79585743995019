import { AxiosError, AxiosResponse } from 'axios';
import { formatShort, stringToDate } from '../../../common/phxDate';
import React from 'react';
import StackedBarChart, { SeriesData, ChartLabels } from '../common/StackedBarChart';
import TooltipContentWithTotal from '../common/TooltipContentWithTotal';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError'
import StackedAreaChart from '../common/StackedAreaChart';

interface Props {
  valuation: { id: number};
  number_of_months: number;
}

interface State {
  data: SeriesData[];
  dataKeys: string[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

interface RawData {
  [noteType: string]: { [date: string]: number };
}

class DiscountedChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'Month',
        yAxis: 'Cash Flow $'
      },
      loaded: false,
      error: null
    };
  }

  componentDidMount = () => {
    ApiService.get('/api/chart_data/cash_flows/discounted', { valuation_id: this.props.valuation.id, number_of_months: this.props.number_of_months })
      .then((response: AxiosResponse) => {
        this.setState({
          data: this.convertToSeries(response.data),
          dataKeys: this.dataKeys(response.data),
          loaded: true
        });
      })
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }));
  }

  convertToSeries(rawData: RawData): SeriesData[] {
    let noteTypeDollarsByDate: { [date: string]: { [noteType: string]: number } } = {};
    let seriesData: SeriesData[] = [];

    for (const [noteType, dollarsByDate] of Object.entries(rawData)) {
      for (const [unformattedDateString, dollars] of Object.entries(dollarsByDate)) {
        const dateString = this.formatDate(unformattedDateString);
        (noteTypeDollarsByDate.hasOwnProperty(dateString)) || (noteTypeDollarsByDate[dateString] = {});
        noteTypeDollarsByDate[dateString][noteType] = dollars;
      }
    }

    for (const [date, dollarsByNoteType] of Object.entries(noteTypeDollarsByDate)) {
      const entry = { name: date, ...dollarsByNoteType };
      seriesData.push(entry);
    }

    return seriesData;
  }

  formatDate(dateString: string) {
    return formatShort(stringToDate(dateString));
  }

  dataKeys(rawData: Object): string[] {
    return Object.keys(rawData)
  }

  yTickFormatter = (tick: any) => {
    return `$${Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD' }).format(tick)}`;
  }

  tooltipFormatter = (val: any) => {
    return `$${Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD' }).format(val)}`;
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <StackedAreaChart
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={this.tooltipFormatter}
      tooltipContent={<TooltipContentWithTotal />}
      data={this.state.data}
      barDataKeys={this.state.dataKeys}
      labels={this.state.labels} />
  }
}

export default DiscountedChart;
