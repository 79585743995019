import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import SeriesLineChart, { ChartDatum } from '../common/SeriesLineChart';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError'
import moment from 'moment/moment';

interface Props {
  valuation: { id: number };
}

interface State {
  data: ChartDatum[];
  dataKeys: string[];
  labels: { xAxis: string, yAxis: string };
  loaded: boolean;
  error: any;
}

class NoteTypeValuePercentChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'Valuation Date',
        yAxis: 'Value %' },
      loaded: false,
      error: null };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/pricings/value_percents`, { valuation_id: this.props.valuation.id, items: 24 })
      .then((response: AxiosResponse) => this.setState({
        data: response.data.data,
        dataKeys: response.data.note_types,
        loaded: true
      }))
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  xTickFormatter = (tick: any) => {
    return moment(tick, 'YYYY-MM-DD').format('MMM \'YY');
  }

  yTickFormatter = (tick: any) => {
    return `${tick.toFixed(3)}%`
  }

  tooltipLabelFormatter = (label: string) => {
    return moment(label, 'YYYY-MM-DD').format('MMM \'YY');
  }

  tooltipFormatter = (value: number, name: string, props: any) => {
    return `${value.toFixed(3)}%`
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <SeriesLineChart
      xTickFormatter={this.xTickFormatter}
      yTickFormatter={this.yTickFormatter}
      tooltipLabelFormatter={this.tooltipLabelFormatter}
      tooltipFormatter={this.tooltipFormatter}
      data={this.state.data}
      lineDataKeys={this.state.dataKeys}
      labels={this.state.labels} />
  }
}

export default NoteTypeValuePercentChart;
