import React from 'react';
import { isPropertySignature } from 'typescript';
import { numberToCompactCurrency, numberToPercentage, numberToMultiple } from '../../../common/phxNumbers';
import { randomInteger } from '../../../common/phxRandom';
import { KpiSet } from '../Kpi';

interface Props {
  kpiSet: KpiSet;
  loaded: boolean;
  error: any;
}

interface State {}

export class KpiPrimary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  renderable = () => {
    return this.props.loaded && this.props.kpiSet && !this.props.error;
  }

  renderStatus = () => {
    if (!this.props.loaded) {
      return this.spinner();
    }
    else {
      if (this.props.error || !this.props.kpiSet) return 'Error';
    }
  }

  valueDollars = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToCompactCurrency(this.props.kpiSet.value_dollars);
  }

  valuePercent = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToPercentage(this.props.kpiSet.value_percent);
  }

  mult = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToMultiple(this.props.kpiSet.mult);
  }

  spinner = () => {
    return (
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  render() {
    return (
      <div key={"kpi-" + randomInteger()}>
        <div className="row d-none d-md-flex">
          <div className="col m-b-30">
            <div className="card valuation-metrics primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 my-auto">
                      <div className="media">
                          <div className="avatar mr-3">
                              <div className="avatar-title rounded-circle value-icon">
                                <i className="mdi mdi-currency-usd"></i>
                              </div>
                          </div>
                          <div className="media-body p-t-5">
                              <h5 className="card-value m-b-0">{this.valueDollars()}</h5>
                              <p className="card-label m-0">Value $</p>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col m-b-30">
            <div className="card valuation-metrics primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 my-auto">
                      <div className="media">
                          <div className="avatar mr-3">
                              <div className="avatar-title rounded-circle percent-icon">
                                <i className="mdi mdi-percent"></i>
                              </div>
                          </div>
                          <div className="media-body p-t-5">
                              <h5 className="card-value m-b-0">{this.valuePercent()}</h5>
                              <p className="card-label m-0">Value %</p>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col m-b-30">
            <div className="card valuation-metrics primary">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 my-auto">
                      <div className="media">
                          <div className="avatar mr-3">
                              <div className="avatar-title rounded-circle mult-icon">
                                X
                              </div>
                          </div>
                          <div className="media-body p-t-5">
                              <h5 className="card-value m-b-0">{this.mult()}</h5>
                              <p className="card-label m-0">Multiple</p>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KpiPrimary;
