import React, { ReactInstance, RefObject, SyntheticEvent } from 'react';

interface Props {
  context?: string;
  property: string;
  errors: any;
}

interface State {
}

export class FormControlError extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errors: {} };
  }

  errors = () => {
    const decontextualizedErrors = this.props.context ? (this.props.errors[this.props.context] || {}) : this.props.errors
    const errors = decontextualizedErrors[this.props.property];
    if (!errors || errors.length == 0) return '';

    return decontextualizedErrors[this.props.property].map((error: string, index: number) => <div key={index}>{error}</div>);
  }

  render() {
    return (
      <div className="invalid-feedback">
        {this.errors()}
      </div>
    );
  }
}

export default FormControlError;
