import { Payload } from "recharts/types/component/DefaultTooltipContent";

export function tooltipPercentFormatter(val: any, name: any, item: any, index: number, payload: Array<Payload<any, any>>) {
  let cumulativeValue = 0;
  let cumulativeRounded = 0;
  let previousBaseline = 0;

  for (let i = 0; i < payload.length; i++) {
    previousBaseline = cumulativeRounded;
    cumulativeValue += payload[i].value;

    if (i == (payload.length - 1)) {
      cumulativeRounded = 100;
    }
    else {
      cumulativeRounded += parseFloat(payload[i].value.toFixed(2));
    }

    if (i == index) break;
  }

  return (cumulativeRounded - previousBaseline).toFixed(2) + '%';
}
