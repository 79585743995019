import React, { ReactElement, ReactNode } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as phxColors from '../../../common/phxColors';
import { BaseAxisProps } from 'recharts/types/util/types';
import { Payload, ValueType, NameType } from 'recharts/lib/component/DefaultTooltipContent'

interface Props {
  data: ChartDatum[];
  barDataKeys: string[];
  yTickFormatter: BaseAxisProps['tickFormatter'],
  tooltipFormatter: Function;
  tooltipContent?: ReactElement;
  tooltipLabelFormatter?: (label: any, payload: Array<Payload<ValueType, NameType>>) => ReactNode;
  labels: ChartLabels;
}

interface State {}

interface ChartDatum {
  [id: string]: any;
}

export interface ChartLabels {
  xAxis: string;
  yAxis: string;
}

export interface SeriesDatum {
  [key: string]: number;
}

export interface SeriesData {
  name: string;
  [key: string]: any;
}

class StackedBarChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  tooltipContent(): ReactElement | undefined {
    if (this.props.tooltipContent) return this.props.tooltipContent;
    return undefined;
  }

  xAxisLabel(): string {
    return (this.props.labels && this.props.labels.xAxis)
  }

  yAxisLabel(): string {
    return (this.props.labels && this.props.labels.yAxis)
  }

  render(){
    return(
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            label={{
              value: this.xAxisLabel(),
              position: 'bottom'
            }}
          />
          <YAxis
            tickFormatter={this.props.yTickFormatter}
            label={{
              value: this.yAxisLabel(),
              angle: -90,
              position: 'left'
            }}
          />
          <Tooltip
            formatter={this.props.tooltipFormatter}
            labelFormatter={this.props.tooltipLabelFormatter}
            content={this.tooltipContent()}
            wrapperStyle={{ zIndex: 1000 }}
          />
          <Legend
            verticalAlign='bottom'
            align='center'
            layout='horizontal'
            wrapperStyle={{
              width: '100%',
              position: 'relative',
              paddingTop: 5
            }}
          />
          {this.props.barDataKeys.map((s, i) => (
            <Bar
              dataKey={s}
              stackId="a"
              fill={phxColors.colorForIndex(i)}
              key={ Math.random().toString(36).substr(2, 9) }
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default StackedBarChart;
