import React from 'react';
import axios from 'axios';
import { numberToCompactCurrency, numberToMultiple, numberToPercentage } from '../../common/phxNumbers';
import KpiExtended from './kpi/KpiExtended';
import KpiPrimary from './kpi/KpiPrimary';
import KpiSet from '../../models/KpiSet';

interface Props {
  valuation: { id: number };
  extended?: boolean;
  primary?: boolean;
}

interface State {
  kpiSet: KpiSet | null;
  loaded: boolean;
  error: any;
}

export class Kpi extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { kpiSet: null, loaded: false, error: null };
  }

  componentDidMount = () => {
    axios('/api/kpi', { params: { valuation_id: this.props.valuation.id }, headers: { 'Accept': 'application/json' } })
      .then((response) => {
        this.setState({ loaded: true, kpiSet: response.data });
      })
      .catch((error) => {
        this.setState({ loaded: true, error: error })
      });
  }

  renderable = () => {
    return this.state.loaded && this.state.kpiSet && !this.state.error;
  }

  renderStatus = () => {
    if (!this.state.loaded) {
      return this.spinner();
    }
    else {
      if (this.state.error || !this.state.kpiSet) return 'Error';
    }
  }

  valueDollars = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToCompactCurrency(this.state.kpiSet.value_dollars);
  }

  valuePercent = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToPercentage(this.state.kpiSet.value_percent);
  }

  mult = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToMultiple(this.state.kpiSet.mult);
  }

  spinner = () => {
    return (
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  render() {
    return (
      <div key="kpi">
        { this.props.primary && <KpiPrimary kpiSet={this.state.kpiSet} loaded={this.state.loaded} error={this.state.error} /> }
        { this.props.extended && <KpiExtended kpiSet={this.state.kpiSet} loaded={this.state.loaded} error={this.state.error} /> }
      </div>
    );
  }
}

export default Kpi;
