import { AxiosError, AxiosResponse } from 'axios';
import { formatShort, stringToDate } from '../../../common/phxDate';
import React from 'react';
import HorizontalStackedBarChart from '../common/HorizontalStackedBarChart';
import { ChartDatum, ChartLabels } from '../common/HorizontalStackedBarChart';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError';
import { tooltipPercentFormatter } from '../common/tooltipUtilities';

interface Props {
  valuation: { id: number };
}

interface State {
  data: ChartDatum[];
  dataKeys: string[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

interface RawData {
  [investor: string]: { [date: string]: number};
}

interface Bar {
  name: string;
  components: Component[];
}

interface Component {
  name: string;
  property: string;
}

class NetServiceFeeBandUpbPercentTrendChart extends React.PureComponent<Props, State> {
  cache = { 'serviceFeeBands': [] as string[]};

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'UPB %',
        yAxis: 'Valuation Date'
      },
      loaded: false,
      error: null
    };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/stratifications/net_service_fee_band_upb_percent`, { valuation_id: this.props.valuation.id, items: 6 })
      .then((response: AxiosResponse) => this.setState({
        data: this.convertToChartData(response.data),
        dataKeys: this.serviceFeeBands(response.data),
        loaded: true
      }))
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  convertToChartData(rawData: RawData): ChartDatum[] {
    let serviceFeePercentagesByDate: { [date: string]: { [serviceFee: string]: number } } = {};

    for (const serviceFee of this.serviceFeeBands(rawData)) {
      const percentagesByDate = rawData[serviceFee];
      for (const [unformattedDateString, percentage] of Object.entries(percentagesByDate)) {
        const dateString = this.formatDate(unformattedDateString);
        (serviceFeePercentagesByDate.hasOwnProperty(dateString)) || (serviceFeePercentagesByDate[dateString] = {});
        serviceFeePercentagesByDate[dateString][serviceFee] = 100.0 * percentage;
      }
    }

    let chartData: ChartDatum[] = [];
    for (const [date, percentagesByServiceFee] of Object.entries(serviceFeePercentagesByDate)) {
      const entry = { name: date, ...percentagesByServiceFee };
      chartData.push(entry);
    }

    return chartData;
  }

  formatDate(dateString: string): string {
    return formatShort(stringToDate(dateString));
  }

  serviceFeeBands(rawData: RawData): string[] {
    if (this.cache['serviceFeeBands'].length > 0) return this.cache['serviceFeeBands'];

    const unsortedServiceFeeBands = Object.keys(rawData);
    const operatorRegex = /[\<\>]+/;

    const sortedServiceFeeBands = unsortedServiceFeeBands.sort((a, b) => {
      const operatorlessA = a.replace(operatorRegex, '');
      const operatorlessB = b.replace(operatorRegex, '');

      if (operatorlessA == operatorlessB) {
        if (a.startsWith('<')) return -1;
        return 1;
      }
      return operatorlessA > operatorlessB ? 1 : -1;
    });

    this.cache['serviceFeeBands'] = sortedServiceFeeBands;
    return sortedServiceFeeBands;
  }

  yTickFormatter = (tick: any) => {
    return `${tick}`;
  }

  xTickFormatter = (tick: any) => {
    return `${tick}%`
  }

  xTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <HorizontalStackedBarChart
      xTickFormatter={this.xTickFormatter}
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={tooltipPercentFormatter}
      data={this.state.data}
      barDataKeys={this.state.dataKeys}
      labels={this.state.labels}
      containerHeight={280}
      barSize={30}
      xTicks={this.xTicks}
      xDomain={[0, 100]}
      xInterval={0}
      yInterval={0} />
  }
}

export default NetServiceFeeBandUpbPercentTrendChart;
