import { AxiosError, AxiosResponse } from 'axios';
import { formatShort, stringToDate } from '../../../common/phxDate';
import React from 'react';
import HorizontalStackedBarChart from '../common/HorizontalStackedBarChart';
import { ChartDatum, ChartLabels } from '../common/HorizontalStackedBarChart';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError';
import { tooltipPercentFormatter } from '../common/tooltipUtilities';

interface Props {
  valuation: { id: number };
}

interface State {
  data: ChartDatum[];
  dataKeys: string[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

interface RawData {
  [balance: string]: { [date: string]: number};
}

interface Bar {
  name: string;
  components: Component[];
}

interface Component {
  name: string;
  property: string;
}

class LoanBalanceUpbPercentTrendChart extends React.PureComponent<Props, State> {
  cache = { loanBalances: [] as string[] };

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'UPB %',
        yAxis: 'Valuation Date'
      },
      loaded: false,
      error: null
    };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/stratifications/loan_balance_upb_percent`, { valuation_id: this.props.valuation.id, items: 6 })
      .then((response: AxiosResponse) => this.setState({
        data: this.convertToChartData(response.data),
        dataKeys: this.loanBalances(response.data),
        loaded: true
      }))
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  convertToChartData(rawData: RawData): ChartDatum[] {
    let loanBalancePercentagesByDate: { [date: string]: { [balance: string]: number } } = {};

    for (const loanBalance of this.loanBalances(rawData)) {
      const percentagesByDate = rawData[loanBalance];
      for (const [unformattedDateString, percentage] of Object.entries(percentagesByDate)) {
        const dateString = this.formatDate(unformattedDateString);
        (loanBalancePercentagesByDate.hasOwnProperty(dateString)) || (loanBalancePercentagesByDate[dateString] = {});
        loanBalancePercentagesByDate[dateString][loanBalance] = 100.0 * percentage;
      }
    }

    let chartData: ChartDatum[] = [];
    for (const [date, percentagesByLoanBalance] of Object.entries(loanBalancePercentagesByDate)) {
      const entry = { name: date, ...percentagesByLoanBalance };
      chartData.push(entry);
    }
    return chartData;
  }

  formatDate(dateString: string): string {
    return formatShort(stringToDate(dateString));
  }

  loanBalances(rawData: RawData): string[] {
    if (this.cache.loanBalances.length > 0) return this.cache.loanBalances;

    const unsortedLoanBalances = Object.keys(rawData);
    const scrubRegex = /[^\.0-9]+/;

    const sortedLoanBalances = unsortedLoanBalances.sort((a, b) => {
      const scrubbedA = a.replace(scrubRegex, '');
      const scrubbedB = b.replace(scrubRegex, '');

      if (scrubbedA == scrubbedB) {
        if (a.startsWith('<')) return -1;
        return 1;
      }
      return parseFloat(scrubbedA) > parseFloat(scrubbedB) ? 1 : -1;
    });

    this.cache.loanBalances = sortedLoanBalances;
    return sortedLoanBalances;
  }

  dataKeys(rawData: RawData): string[] {
    return Object.keys(rawData);
  }

  yTickFormatter = (tick: any) => {
    return `${tick}`;
  }

  xTickFormatter = (tick: any) => {
    return `${tick}%`
  }

  xTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <HorizontalStackedBarChart
      xTickFormatter={this.xTickFormatter}
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={tooltipPercentFormatter}
      data={this.state.data}
      barDataKeys={this.state.dataKeys}
      labels={this.state.labels}
      barCategoryGap={2}
      xTicks={this.xTicks}
      xDomain={[0, 100]}
      xInterval={0} />
  }
}

export default LoanBalanceUpbPercentTrendChart;
