import { AxiosResponse } from 'axios';
import React from 'react';
import * as phxDate from '../../../common/phxDate';
import StackedBarChart, { SeriesData } from '../common/StackedBarChart';
import TooltipContentWithTotal from '../common/TooltipContentWithTotal';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner'
import DataError from '../../shared/DataError';

interface Props {
  valuation: { id: number }
}

interface State {
  data: SeriesData[];
  dataKeys: string[];
  labels: { xAxis: string, yAxis: string };
  loaded: boolean;
  error: any;
}

interface RawData {
  [noteType: string]: { [date: string]: number };
}

class NoteTypeValueDollarChart extends React.PureComponent<Props, State> {
  currencyFormatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }); // Do we want full dollars for tooltip?
  currencyFormatterCompact = Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD' });

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'Valuation Date',
        yAxis: 'Value $' },
      loaded: false,
      error: null };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/pricings/value_dollars`, { valuation_id: this.props.valuation.id, items: 24 })
      .then((response: AxiosResponse) => this.setState({
        data: this.convertToSeries(response.data),
        dataKeys: this.dataKeys(response.data),
        loaded: true
      }))
      .catch((error) => this.setState({ loaded: true, error: error }))
  }

  convertToSeries(rawData: RawData): SeriesData[] {
    let noteTypeDollarsByDate: { [date: string]: { [noteType: string]: number } } = {};
    let seriesData: SeriesData[] = [];

    for (const [noteType, dollarsByDate] of Object.entries(rawData)) {
      for (const [unformattedDateString, dollars] of Object.entries(dollarsByDate)) {
        const dateString = phxDate.formatShort(phxDate.stringToDate(unformattedDateString));
        (noteTypeDollarsByDate.hasOwnProperty(dateString)) || (noteTypeDollarsByDate[dateString] = {});
        noteTypeDollarsByDate[dateString][noteType] = dollars;
      }
    }

    for (const [date, dollarsByNoteType] of Object.entries(noteTypeDollarsByDate)) {
      const entry = { name: date, ...dollarsByNoteType };
      seriesData.push(entry);
    }

    return seriesData;
  }

  dataKeys(rawData: Object): string[] {
    return Object.keys(rawData)
  }

  yTickFormatter = (tick: any) => {
    return `$${this.currencyFormatterCompact.format(tick)}`;
  }

  tooltipFormatter = (value: number, name: string, props: any) => {
    return `$${this.currencyFormatterCompact.format(value)}`;
  }

  render(){
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    )
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <StackedBarChart
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={this.tooltipFormatter}
      tooltipContent={<TooltipContentWithTotal />}
      data={this.state.data}
      barDataKeys={this.state.dataKeys}
      labels={this.state.labels} />
  }
}

export default NoteTypeValueDollarChart;
