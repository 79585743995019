import React, { ReactInstance, RefObject, SyntheticEvent } from 'react';

interface Props {
  // spinnerClass?: string;
  width?: string;
  height?: string;
}

interface State {
}

export class Spinner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  width = (): string => {
    if (this.props.width) return this.props.width;
    return '1.5rem';
  }

  height = (): string => {
    if (this.props.height) return this.props.height;
    return '1.5rem';
  }

  style = (): React.CSSProperties => {
    return { width: this.width(), height: this.height() };
  }

  render() {
    return (
      <div className={`spinner-border`} style={this.style()} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
}

export default Spinner;
