import * as JSZip from 'jszip'
import { saveAs } from 'file-saver';

interface DeliverableWithUrl {
  filename: string;
  url: string;
}

interface InputType {
  name: string;
  input: Response;
}

export default class ValuationDeliverablePackager {
  link: HTMLElement;

  constructor(link: HTMLElement) {
    this.link = link
    this.link.addEventListener("click", (event: Event) => this.download(event));
  }

  private download(event: Event) {
    event.preventDefault();
    this.disableLink();

    this.fetchDeliverablesWithUrl()
      .then(async (deliverableWithUrls: DeliverableWithUrl[]) => {
        const zip = new JSZip();

        await Promise.all(deliverableWithUrls.map((deliverableWithUrl) => {
          return fetch(deliverableWithUrl.url).then(async (response) => {
            const blob = await response.blob();
            zip.file(deliverableWithUrl.filename, blob);
          })
        }));

        zip.generateAsync({ type: 'blob' })
          .then((content) => {
            saveAs(content, `PHOENIX_PV940${this.link.dataset.valuationId}_${this.timestamp()}`);
            this.enableLink();
          });
      })
      .catch((error) => {
        this.errorLink();
      })
  }

  private async fetchDeliverablesWithUrl(): Promise<DeliverableWithUrl[]> {
    const headers = new Headers();
    headers.set('Accept', 'application/json');

    return fetch(`/valuations/${this.link.dataset.valuationUuid}/deliverables/urls`, { headers: headers })
      .then(response => {
        if (response.ok) return response.json();
        return [];
      })
      .catch((error) => {
        this.errorLink();
        return [];
      });
  }

  private timestamp(): string {
    return new Date().toISOString().replace(/[^0-9]/g, '').slice(0, -3);
  }

  private disableLink() {
    this.link.classList.add("disabled");
    this.link.innerHTML = 'Processing <div class="spinner-border spinner-border-sm" role="status"></div>';
  }

  private enableLink() {
    this.link.classList.remove("disabled");
    this.link.innerHTML = 'Download all';
  }

  private errorLink() {
    this.disableLink();
    this.link.innerHTML = 'Error encountered';
  }
}
