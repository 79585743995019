// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

require('bootstrap')

require('../initializers/mixpanel')
require('../initializers/select2')

import '../stylesheets/application';
import 'daterangepicker/daterangepicker.js'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'pace-js/pace.min'
import 'list.js/dist/list'
import 'jquery.scrollbar/jquery.scrollbar'
import 'select2/dist/js/select2.full.min'

global.$ = window.jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../img', true)
const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true, /^(?!.*\.(spec|test)(\.[jt]sx?)?$).*$/);

var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(() => {
  require('../vendor/atmos')
});
