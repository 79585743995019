import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import React from 'react';

const TooltipContentWithTotal = (props: TooltipProps<number, string>) => {
  if (!props.active || !props.payload) return null;
  const payloadWithTotal = [
    ...props.payload as Payload<number, string>[],
    {
      name: 'Total',
      value: props.payload.reduce((sum: number, payload: any) => sum + payload.value, 0)
    }
  ];
  return <DefaultTooltipContent {...props} payload={payloadWithTotal} />;
};

export default TooltipContentWithTotal;
