// TODO: What happens if we need a color for too high of an index?
// TODO: Test once merged to branch with testing apparatus

export const PHX_PRUSSIAN_BLUE = '#364070';
export const PHX_STEEL_BLUE = '#4F5F9C'

export const PHX_CHERRY_RED = '#D62027';
export const PHX_ROSE_RED = '#ED2024';

export const PHX_MERIGOLD_ORANGE = '#FBAA19';

export const PHX_LEMON_YELLOW = '#FBE40A';

export const PHX_FERN_GREEN = '#69C28C';

export const PHX_COIN_GRAY = '#A7A9AC';

export const SAGE_GREEN = '#488B6E';
export const MINT_GREEN = '#77DF7C';
export const CHARTREUSE_GREEN = '#ADEA6B';

export const CORNFLOWER_BLUE = '#417EE4';

export const MELLOW_YELLOW = '#DABF41';

export const APRICOT_ORANGE = '#BF752C';

export const ROBIN_EGG_CYAN = '#56BCC6';

export const BLUE_VIOLET = '#4F44CB';
export const LILAC_PURPLE = '#8F89F3';
export const REBECCA_PURPLE = '#683BAB';

export const MAGENTA_PINK = '#C9438C';

export const JUST_BLACK = '#000000'

export function colorForIndex(i: number): string {
  const phxColors = [
    PHX_STEEL_BLUE,
    PHX_ROSE_RED,
    PHX_MERIGOLD_ORANGE,
    PHX_FERN_GREEN,
    PHX_LEMON_YELLOW,
    ROBIN_EGG_CYAN,
    BLUE_VIOLET,
    PHX_COIN_GRAY,
    MAGENTA_PINK,
    LILAC_PURPLE,
    MINT_GREEN,
    CORNFLOWER_BLUE,
    REBECCA_PURPLE,
    MELLOW_YELLOW,
    APRICOT_ORANGE,
    SAGE_GREEN,
    CHARTREUSE_GREEN
  ]

  return phxColors[i] || JUST_BLACK;
}
