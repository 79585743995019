import React from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import Spinner from '../shared/Spinner';
import DataError from '../shared/DataError';
import { ApiService } from '../../services/ApiService';

interface Props {
  data_date: string
}

interface State {
  data: ClosingMarketPrice[]
  loaded: boolean
  error: any
}

interface Rate {
  name: string
  remote_name: string
  remote_coupon_name: string
}

interface Rate {
  name: string,
  remote_name: string
  remote_coupon_name: string
  value: number
  coupon_value: number
}

export class MarketRateRatesTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { loaded: false, data: [], error: null }
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/market_rates/rates`, { data_date: this.props.data_date })
      .then((response: AxiosResponse) => {
        this.setState({
          data: response.data,
          loaded: true
        });
      })
      .catch((error: AxiosError) => {
        this.setState({ loaded: true, error: error });
      })
  }

  render(){
    return this.state.loaded ? this.renderTable() : this.renderLoading();
  }

  private remoteNames = (): string[] => {
    return this.props.spreads.map(r => r.remote_name).filter(Boolean);
  }

  private couponNames = (): string[] => {
    return this.props.spreads.map(r => r.coupon_name).filter(Boolean);
  }

  private valueFor = (name: string): number => {
    const closingMarketPrice = this.state.data.find((closingMarketPrice: ClosingMarketPrice) => {
      return closingMarketPrice.name === name
    });
    return closingMarketPrice ? closingMarketPrice.value : 0.0;
  }

  private renderLoading = () => {
    return <Spinner width="3rem" height="3rem" />;
  }

  private renderTable = () => {
    if (this.state.error)
      return <DataError />

    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th className="text-right">Daily Rate</th>
            <th className="text-right">Spread</th>
            <th className="text-right">Par Rate</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((rate) => {
            return <tr key={rate.name}>
              <td>{rate.name}</td>
              <td className="text-right">{rate.coupon_value.toFixed(3)}</td>
              <td className="text-right">{(rate.value - rate.coupon_value).toFixed(3)}</td>
              <td className="text-right">{rate.value.toFixed(3)}</td>
            </tr>
          })}
        </tbody>
      </table>
    );
  }
}

export default MarketRateRatesTable;
