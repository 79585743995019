import React from 'react';

const InfoPopUp = (props: any) => {
  const { placement, icon_size, message } = props;

  return (
    <i 
      className="mdi mdi-information-outline"
      data-toggle="tooltip"
      data-placement={ placement || "top" }
      title={ message }
      style={ { fontSize: icon_size || "15px" } }
    >
    </i>
  );
}

export default InfoPopUp;
