import { AxiosError, AxiosResponse } from 'axios';
import { formatShort, stringToDate } from '../../../common/phxDate';
import React from 'react';
import HorizontalStackedBarChart from '../common/HorizontalStackedBarChart';
import { ChartDatum, ChartLabels } from '../common/HorizontalStackedBarChart';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError';
import { tooltipPercentFormatter } from '../common/tooltipUtilities';

interface Props {
  valuation: { id: number };
}

interface State {
  data: ChartDatum[];
  dataKeys: string[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

interface RawData {
  [noteType: string]: { [date: string]: number};
}

interface Bar {
  name: string;
  components: Component[];
}

interface Component {
  name: string;
  property: string;
}

class NoteTypeUpbPercentTrendChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'UPB %',
        yAxis: 'Valuation Date'
      },
      loaded: false,
      error: null
    };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/stratifications/note_type_upb_percent`, { valuation_id: this.props.valuation.id, items: 6 })
      .then((response: AxiosResponse) => this.setState({
        data: this.convertToChartData(response.data),
        dataKeys: this.dataKeys(response.data),
        loaded: true
      }))
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  convertToChartData(rawData: RawData): ChartDatum[] {
    let noteTypePercentagesByDate: { [date: string]: { [investor: string]: number } } = {};

    for (const [noteType, percentagesByDate] of Object.entries(rawData)) {
      for (const [unformattedDateString, percentage] of Object.entries(percentagesByDate)) {
        const dateString = this.formatDate(unformattedDateString);
        (noteTypePercentagesByDate.hasOwnProperty(dateString)) || (noteTypePercentagesByDate[dateString] = {});
        noteTypePercentagesByDate[dateString][noteType] = 100.0 * percentage;
      }
    }

    let chartData: ChartDatum[] = [];
    for (const [date, percentagesByNoteType] of Object.entries(noteTypePercentagesByDate)) {
      const entry = { name: date, ...percentagesByNoteType };
      chartData.push(entry);
    }

    return chartData;
  }

  formatDate(dateString: string): string {
    return formatShort(stringToDate(dateString));
  }

  dataKeys(rawData: RawData): string[] {
    return Object.keys(rawData);
  }

  yTickFormatter = (tick: any) => {
    return `${tick}`;
  }

  xTickFormatter = (tick: any) => {
    return `${tick}%`
  }

  xTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <HorizontalStackedBarChart
      xTickFormatter={this.xTickFormatter}
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={tooltipPercentFormatter}
      data={this.state.data}
      barDataKeys={this.state.dataKeys}
      labels={this.state.labels}
      barCategoryGap={2}
      xTicks={this.xTicks}
      xDomain={[0, 100]}
      xInterval={0} />
  }
}

export default NoteTypeUpbPercentTrendChart;
