import { AxiosResponse } from "axios";
import { GLOBAL_CACHE_TTL } from "../common/phxConstants";

const cachios = require('cachios');

export namespace ApiService {
  export const get = async(url: string, params: any = {}, force: boolean = false): Promise<AxiosResponse> => {
    return cachios.get(url, { ttl: GLOBAL_CACHE_TTL, force: force, params: params })
      .then((response: AxiosResponse) => response);
  }
}
