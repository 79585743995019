import React from 'react';
import DataError from '../shared/DataError';
import Spinner from '../shared/Spinner';

interface Props {
  columns: string[];
  dimensions: { [key: string]: any };
  error: any;
  lastItemRef: React.RefObject<HTMLTableRowElement>;
  measures: { [key: string]: any };
  paginatedDataLoaded: boolean;
  records: { [key: string]: any }[];
  selectedReportConfig: { [key: string]: any } | null;
  shockConfigTypes: any;
  reportsProcessed: boolean;
  valuations: { [key: string]: any }[];
  reportConfigs: { [key: string]: any }[];
}

const ShockReportTable = (props: Props) => {
  const {
    columns,
    dimensions,
    error,
    lastItemRef,
    measures,
    paginatedDataLoaded,
    records,
    selectedReportConfig,
    shockConfigTypes,
    reportsProcessed,
    valuations,
    reportConfigs
  } = props;

  const propertyNumeric = (property: string) => {
    return (measures[property] && measures[property]["numeric"]) || 
      (dimensions[property] && dimensions[property]["numeric"]) ||
      (!measures[property] && !dimensions[property])
  }

  const formatShockColumnName = (col_name: string) => {
    let number = parseFloat(col_name);
    let shockColumnName;

    if (number === 0) {
      shockColumnName = 'Base'
    } else if (number < 0) {
      shockColumnName = Math.abs(number % 1 === 0 ? Math.floor(number) : number) + 'D'
    } else {
      shockColumnName = (number % 1 === 0 ? Math.floor(number) : number) + 'U';
    }

    const shockConfigType = selectedReportConfig?.type;

    switch (true) {
      case (shockConfigType === shockConfigTypes['dollar']):
        shockColumnName += ' $';
        break;
      case (shockConfigType === shockConfigTypes['bp']):
        shockColumnName += ' BP';
        break;
      case (shockConfigType === shockConfigTypes['cpr']):
        shockColumnName += ' CPR';
        break;
    }

    return shockColumnName;
  }

  const renderMessage = () => {
    if (valuations.length === 0) {
      return <em>No valuations available for reporting</em>;
    } else if (reportConfigs.length === 0) {
      return <em>No reports are configured</em>;
    } else if (reportsProcessed) {
      return <em>No records available for this report</em>;
    } else {
      return <em>Pricing report has not been generated</em>;
    }
  }

  const renderColumnName = (col_name: string) => {
    if (!!selectedReportConfig?.dimension_name_overrides[col_name]) {
      return selectedReportConfig?.dimension_name_overrides[col_name];
    } else if (measures[col_name]) {
      return measures[col_name]['name']
    } else if (dimensions[col_name]) {
      return dimensions[col_name]['name']
    } else {
      return formatShockColumnName(col_name);
    }
  }

  const renderValue = (col_name: string, record: any) => {
    const recordHasKey = col_name in record

    if (recordHasKey && record[col_name] == null) return "<NULL>";
    if (!recordHasKey && record[col_name] == null) return "***";

    return formatValue(col_name, record[col_name]);
  }

  const renderTable = () => {
    return records.map((rec: { [key: string]: any }, index: number) => {
      const record = rec.data
      return (
        <React.Fragment key={ index }>
          { record['total'] ? (
            <tr data-testid="totalSeparator" className="total-separator">
              { columns.map((col_name: string, i: number) => 
                <td key={ i } className={ ( col_name === "0.0" ? "col-highlight " : "") }></td>
              )}
            </tr>) : null }
          
          { index === records.length - 1 ? (
            <>
              <tr ref={lastItemRef} className={ (record['total'] || record['subtotal']) ? "total" : ""}>
                { columns.map((col_name: string, i: number) => 
                  <td key={ i } className={ ( col_name === "0.0" ? "col-highlight " : "") + (propertyNumeric(col_name) ? "text-end" : "") }>
                    { renderValue(col_name, record) }
                  </td>
                )}
              </tr>

              { !paginatedDataLoaded ? (
                <tr>
                  <td className="pt-2 pb-2">
                    <span className="ml-1 mr-2">
                      <em>Loading</em>
                    </span>
                    <Spinner width="0.8rem" height="0.8rem" />
                  </td>                
                </tr>
              ) : null }
            </>
      
          ) : (
            <tr className={ (record['total'] || record['subtotal']) ? "total" : ""}>
              { columns.map((col_name: string, i: number) => 
                <td key={ i } className={ ( col_name === "0.0" ? "col-highlight " : "") + (propertyNumeric(col_name) ? "text-end" : "") }>
                  { renderValue(col_name, record) }
                </td>
              )}
            </tr>
          ) }
          
          { (record['subtotal'] && 
            records[index + 1] && 
            !records[index + 1]['total'] && 
            !records[index + 1]['subtotal']) ? (
            <tr data-testid="totalSeparator" className="total-separator">
              { columns.map((col_name: string, i: number) => 
                <td key={ i } className={ ( col_name === "0.0" ? "col-highlight " : "") }></td>
              )}
            </tr>) :
          null }
        </React.Fragment>
      );
    });
  }

  const formatValue = (col_name: string, value: any) => {
    if (value == null || !measures[col_name] && dimensions[col_name]) return value;

    const precision = measures[col_name] ? measures[col_name]["precision"] : null;

    if (!precision) {
      const shockConfigType = selectedReportConfig?.type;
    
      if (!measures[col_name] && !dimensions[col_name] &&
        (shockConfigType === shockConfigTypes['bp'] || shockConfigType === shockConfigTypes['cpr'])) {
        return parseFloat(value).toFixed(2);
      }

      return parseFloat(value).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return parseFloat(value).toFixed(precision);
  }

  return (
    <>
      { error ? (
        <DataError />
      ) : (
        <div>        
          { records.length ? (
            <div className="table-responsive" id="shock-report-table-container">
              <table className="table table-hover table-sm table-light">
                <thead className="thead-dark">
                  <tr>
                    { columns.map((col_name: string, index: number) => 
                        <th key={ index }  className={ propertyNumeric(col_name) ? "text-end" : "" }>
                          { renderColumnName(col_name) }
                        </th>)}
                  </tr>
                </thead>
                <tbody>
                  { renderTable() }
                </tbody>
              </table> 
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-50">
              { renderMessage() }
            </div>
          ) } 
        </div>
      ) }
    </>
  );
}

export default ShockReportTable;
