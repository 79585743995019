import React, { RefObject, SyntheticEvent } from 'react';
import axios from 'axios';
import { formatShort, stringToDate } from '../common/phxDate';

interface Props {
  portfolio: { id: number }
  valuation: Valuation
}

interface State {
  valuationUuid: string
  valuations: Valuation[]
}

interface Valuation {
  id: number
  uuid: string
  as_of: string
  status: string
}

export class ValuationSelector extends React.Component<Props, State> {
  selectedDatasetRef: RefObject<HTMLAnchorElement>;

  constructor(props: Props) {
    super(props);
    this.state = { valuationUuid: props.valuation.uuid, valuations: [] };

    this.selectedDatasetRef = React.createRef();
  }

  componentDidMount = () => {
    axios(`/portfolios/${this.props.portfolio.id}/valuations`, { headers: { 'Accept': 'application/json' } })
      .then((response) => {
        this.setState({ valuations: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  valuationName(valuation: Valuation): string {
    let formatted = formatShort(stringToDate(valuation.as_of));
    if (valuation.status !== 'approved') formatted += " (unapproved)";
    return formatted;
  }

  showSpinner() {
    if (!this.selectedDatasetRef.current) return;
    this.selectedDatasetRef.current.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>';
  }

  setValuation(event: SyntheticEvent, valuationUuid: string) {
    event.preventDefault();

    this.setState({ valuationUuid: valuationUuid});
    this.showSpinner();

    const path = window.location.pathname.replace(/(valuations\/)(.*?)($|\/.*)/, `$1${valuationUuid}$3`);
    window.location.href = path;
  }

  items(): React.HTMLProps<HTMLAnchorElement>[] {
    if (this.state.valuations.length == 0) return [];
    let items: React.HTMLProps<HTMLAnchorElement>[] = [];
    this.state.valuations.forEach((valuation) => {
      items.push(<a className="dropdown-item" href="#" key={valuation.uuid} onClick={(e) => this.setValuation(e, valuation.uuid)}>{this.valuationName(valuation)}</a>);
    });
    return items;
  }

  render() {
    return (
      <div>
        <p className="mb-1">Valuation Month</p>
        <div className="dropdown show float-right">
          <a className="dropdown-toggle font-weight-bold" ref={this.selectedDatasetRef} href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {this.valuationName(this.props.valuation)}
          </a>

          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {this.items()}
          </div>
        </div>
      </div>
    );
  }
}

export default ValuationSelector;
