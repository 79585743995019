import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import WaterfallChart, { ChartDatum, ChartLabels } from '../common/WaterfallChart';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError'

interface Props {
  valuation: { id: number};
}

interface State {
  data: ChartDatum[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

interface RawData {
  [key: string]: number;
}

interface Bar {
  name: string;
  components: Component[];
}

interface Component {
  name: string;
  property: string;
}

class DecompositionChart extends React.PureComponent<Props, State> {
  revenueComponents: Component[] = [
    { name: 'Servicing Fee Revenue', property: 'SERVICING_FEE_REVENUE' },
    { name: 'Ancillary Revenue', property: 'ANCILLARY_REVENUE' },
    { name: 'Float Revenue', property: 'FLOAT_REVENUE' }
  ];

  costComponents: Component[] = [
    { name: 'Advances Cost', property: 'ADVANCES_COST' },
    { name: 'Core Servicing Cost', property: 'CORE_SERVICING_COST' },
    { name: 'Defaulted Servicing Cost', property: 'DEFAULTED_SERVICING_COST' }
  ];

  discountComponents: Component[] = [
    { name: 'Yield', property: 'YIELD' },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      labels: {
        xAxis: 'Cash Flow Components',
        yAxis: 'Cash Flow $' },
      loaded: false,
      error: null };
  }

  componentDidMount = () => {
    ApiService.get('/api/chart_data/cash_flows/decomposition', { valuation_id: this.props.valuation.id })
      .then((response: AxiosResponse) => this.setState({ data: this.convertToChartData(response.data), loaded: true }))
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  convertToChartData(rawData: RawData): ChartDatum[] {
    let chartData: ChartDatum[] = [];

    let startingValue = 0;

    let total = 0;
    this.revenueComponents.forEach((revenueComponent) => {
      const value = rawData[revenueComponent.property];
      chartData.push({ name: revenueComponent.name, value: value, startingValue: startingValue });

      startingValue += value;
      total += value;
    });
    chartData.push({ name: 'Total Revenue', value: total, startingValue: 0, total: true });

    this.costComponents.forEach((costComponent) => {
      const value = rawData[costComponent.property];
      chartData.push({ name: costComponent.name, value: value, startingValue: startingValue });

      startingValue += value;
      total += value;
    });
    chartData.push({ name: 'Total Cash Flow', value: total, startingValue: 0, total: true });

    this.discountComponents.forEach((discountComponent) => {
      const value = rawData[discountComponent.property];
      chartData.push({ name: discountComponent.name, value: value, startingValue: startingValue });

      startingValue += value;
      total += value;
    });
    chartData.push({ name: 'Total Discounted Cash Flow', value: total, startingValue: 0, total: true });

    return chartData;
  }

  yTickFormatter = (tick: any) => {
    return `$${Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD' }).format(tick)}`;
  }

  tooltipFormatter = (val: any) => {
    return `$${Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD' }).format(val)}`;
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <WaterfallChart
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={this.tooltipFormatter}
      data={this.state.data}
      labels={this.state.labels} />
  }
}

export default DecompositionChart;
