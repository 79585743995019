import React from 'react';
import { numberToCompactCurrency, numberToPercentage, numberToCurrency, numberWithDelimiter } from '../../../common/phxNumbers';
import { KpiSet } from '../Kpi';

interface Props {
  kpiSet: KpiSet;
  loaded: boolean;
  error: any;
}

interface State {}

export class KpiExtended extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  renderable = () => {
    return this.props.loaded && this.props.kpiSet && !this.props.error;
  }

  renderStatus = () => {
    if (!this.props.loaded) {
      return this.spinner();
    }
    else {
      if (this.props.error || !this.props.kpiSet) return 'Error';
    }
  }

  upbDollars = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToCompactCurrency(this.props.kpiSet.upb_dollars);
  }

  loanCount = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberWithDelimiter(this.props.kpiSet.loan_count);
  }

  nsf = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToPercentage(this.props.kpiSet.net_service_fee);
  }

  wac = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToPercentage(this.props.kpiSet.wac);
  }

  avgBal = () => {
    if (!this.renderable()) return this.renderStatus();
    return numberToCurrency(this.props.kpiSet.avg_bal);
  }

  age = () => {
    if (!this.renderable()) return this.renderStatus();
    return this.props.kpiSet.age.toFixed(1) + ' months';
  }

  spinner = () => {
    return (
      <span className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </span>
    )
  }

  item = (title: string, value: any) => {
    return (
      <div className="col m-b-30">
        <div className="card valuation-metrics secondary">
          <div className="card-body">
            <div className="text-center p-t-10">
              <h6 className='card-title'>{title}</h6>
              <p className='card-content'>{value}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="row d-none d-md-flex">
        {this.item('UPB', this.upbDollars())}
        {this.item('Loan Count', this.loanCount())}
        {this.item('NSF', this.nsf())}
        {this.item('WAC', this.wac())}
        {this.item('Avg Bal', this.avgBal())}
        {this.item('Age', this.age())}
      </div>
    );
  }
}

export default KpiExtended;
