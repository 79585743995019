import React, { ReactElement, ReactNode } from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as phxColors from '../../../common/phxColors';
import { BaseAxisProps } from 'recharts/types/util/types';
import { Payload, ValueType, NameType } from 'recharts/lib/component/DefaultTooltipContent'

interface Props {
  id?: string
  data: ChartDatum[]
  lineDataKeys: string[]
  areaDataKeys: string[]
  xTickFormatter?: BaseAxisProps['tickFormatter']
  yTickFormatter: BaseAxisProps['tickFormatter']
  tooltipFormatter?: Function
  tooltipContent?: ReactElement
  tooltipLabelFormatter?: (label: any, payload: Array<Payload<ValueType, NameType>>) => ReactNode
  labels: ChartLabels
  dots?: boolean
  animationsEnabled?: boolean
}

interface State {}

export interface ChartDatum {
  [name: string]: any;
}

export interface ChartLabels {
  xAxis?: string
  yAxis?: string
  yAxisSecondary?: string
}

class SeriesLineWithAreaChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  private legendFormatter = (value: string, entry: any) => {
    return <span style={{ color: 'black' }}>{value}</span>;
  }

  tooltipContent(): ReactElement | undefined {
    if (this.props.tooltipContent) return this.props.tooltipContent;
    return undefined;
  }

  dots(i: number): ReactElement | boolean {
    return this.props.dots == false ? false : { stroke: phxColors.colorForIndex(i), strokeWidth: 4 };
  }

  enableAnimations(): boolean {
    return this.props.animationsEnabled == false ? false : true;
  }

  render(){
    return (
      <ResponsiveContainer
        id={this.props.id}
        width="100%"
        height="100%"
      >
        <ComposedChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            type="category"
            allowDuplicatedCategory={false}
            angle={-45}
            textAnchor='end'
            tickFormatter={this.props.xTickFormatter}
            label={{
              value: this.props.labels.xAxis,
              position: 'bottom'
            }}
          />
          <YAxis
            yAxisId={"left"}
            domain={['auto', 'auto']}
            tickFormatter={this.props.yTickFormatter}
            label={{
              value: this.props.labels.yAxis,
              angle: -90,
              position: 'left'
            }}
          />
          <YAxis
            yAxisId='right'
            orientation='right'
            domain={['auto', 'auto']}
            tickFormatter={this.props.yTickFormatter}
            label={{
              value: this.props.labels.yAxisSecondary,
              angle: 90,
              position: 'right'
            }}
          />
          <Tooltip
            formatter={this.props.tooltipFormatter}
            labelFormatter={this.props.tooltipLabelFormatter}
            content={this.tooltipContent()}
            wrapperStyle={{ zIndex: 1000 }}
          />
          <Legend
            verticalAlign='bottom'
            align='center'
            layout='horizontal'
            iconType='rect'
            formatter={this.legendFormatter}
            wrapperStyle={{
              width: '100%',
              position: 'relative',
              paddingTop: 5
            }}
          />
          {this.props.areaDataKeys.map((s, i) => (
            <Area
              yAxisId={"right"}
              stroke={'#DBE3F3'}
              fill={'#DBE3F3'}
              dataKey={s}
              key={Math.random().toString(36).substr(2, 9)}
              isAnimationActive={this.enableAnimations()}
            />
          ))}
          {this.props.lineDataKeys.map((s, i) => (
            <Line
              yAxisId={"left"}
              dot={this.dots(i)}
              strokeWidth={3}
              stroke={phxColors.colorForIndex(i)}
              dataKey={s}
              key={Math.random().toString(36).substr(2, 9)}
              isAnimationActive={this.enableAnimations()}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    )
  }
}

export default SeriesLineWithAreaChart;
