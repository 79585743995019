import axios, { AxiosError } from 'axios';
import * as htmlToImage from "html-to-image";
import { waitForElement } from '../../common/Utilities'

export default class MarketRatesEmailPublisher {
  link: HTMLButtonElement;
  successDisplay: HTMLElement|null;
  failureDisplay: HTMLElement|null;

  constructor(link: HTMLButtonElement) {
    this.link = link;
    this.link.addEventListener('click', (event: Event) => this.sendToMailchimp(event));
    this.successDisplay = document.getElementById('successMessageId');
    this.failureDisplay = document.getElementById('failureMessageId');
    waitForElement('marketRatesChart').then((elm) => {
      this.enableLink();
    });
  }

  private async sendToMailchimp(event: Event) {
    event.preventDefault();
    this.disableLink();
    this.clearDisplayMessages();

    try {
      const chart = document.getElementById('marketRatesChart');
      const csrf = (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;
      const dataUrl = await htmlToImage.toPng(chart!);
      await axios.post(`/market_rates/publish`, { 'chart': dataUrl }, { headers: { 'X-CSRF-Token': csrf }});
      this.setSuccessMessage();
    } catch(error: any|AxiosError) {
      const message = axios.isAxiosError(error) ? error.response?.data.message : error.message;
      this.setFailureMessage(message);
    }
    this.enableLink();
  }

  private setSuccessMessage() {
    if (this.successDisplay) {
      this.successDisplay.innerHTML = `Template successfully submitted to Mailchimp`;
    }
  }

  private setFailureMessage(message: string) {
    if (this.failureDisplay) {
      this.failureDisplay.innerHTML =`An error was encountered while submitting the email template to Mailchimp: ${message}.`;
    }
  }

  private clearDisplayMessages() {
    if (this.successDisplay) {
      this.successDisplay.innerHTML = ``;
    }
    if (this.failureDisplay) {
      this.failureDisplay.innerHTML = ``;
    }
  }

  private disableLink() {
    this.link.disabled = true;
    this.link.innerHTML = 'Sending... <div class="spinner-border spinner-border-sm" role="status"></div>';
  }

  private enableLink() {
    this.link.disabled = false;
    this.link.innerHTML = 'Send to Mailchimp';
  }
}
