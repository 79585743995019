export function numberWithDelimiter(num: number, precision: number = 0): string {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: precision, maximumFractionDigits: precision }).format(num);
}

export function numberToCurrency(num: number, precision: number = 0): string {
  return '$' + Intl.NumberFormat('en-US', { currency: 'USD', minimumFractionDigits: precision, maximumFractionDigits: precision }).format(num);
}

export function numberToCompactCurrency(num: number, precision: number = 3): string {
  return '$' + Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD', minimumFractionDigits: precision, maximumFractionDigits: precision }).format(num);
}

export function numberToPercentage(num: number, precision: number = 3): string {
  return num.toFixed(precision) + '%';
}

export function numberToMultiple(num: number, precision: number = 3): string {
  return num.toFixed(precision) + 'x';
}

function truncate(num: number, precision: number) {
  var re = new RegExp("(\\d+\\.\\d{" + precision + "})(\\d)"),
      m = num.toString().match(re);
  return m ? parseFloat(m[1]) : num.valueOf();
};

function truncatedIntlNumberFormat(formatted: string, precision: number = 0) {
  const matched = formatted.match(/(.+)\.(\d+)(\w+)?|(.+)/);
  if (!matched) return formatted;

  const compactKey = matched[3] || '';
  if (precision == 0) return matched[1] + compactKey;

  const decimal = matched[2] || '';
  return matched[1] + "." + decimal.substring(0, precision) + compactKey;
}
