import { AxiosError, AxiosResponse } from 'axios';
import { formatShort, stringToDate } from '../../../common/phxDate';
import React from 'react';
import HorizontalStackedBarChart from '../common/HorizontalStackedBarChart';
import { ChartDatum, ChartLabels } from '../common/HorizontalStackedBarChart';
import TooltipContentWithTotal from '../common/TooltipContentWithTotal';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError'

interface Props {
  valuation: { id: number };
}

interface State {
  data: ChartDatum[];
  dataKeys: string[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

interface RawData {
  [delinquencyCategory: string]: { [date: string]: number};
}

class DelinquencyCategoryLoanCountPercentTrendChart extends React.PureComponent<Props, State> {
  cache = { delinquencyCategories: [] as string[], filteredData: {} as RawData };

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'Loan Count %',
        yAxis: 'Valuation Date'
      },
      loaded: false,
      error: null
    };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/stratifications/delinquency_category_loan_count_percent`, { valuation_id: this.props.valuation.id, items: 6 })
      .then((response: AxiosResponse) => this.setState({
        data: this.convertToChartData(this.filterData(response.data)),
        dataKeys: this.delinquencyCategories(this.filterData(response.data)),
        loaded: true
      }))
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  convertToChartData(rawData: RawData): ChartDatum[] {
    let delinquencyCategoryPercentagesByDate: { [date: string]: { [delinquencyCategory: string]: number } } = {};

    for (const delinquencyCategory of this.delinquencyCategories(rawData)) {
      const percentagesByDate = rawData[delinquencyCategory];
      for (const [unformattedDateString, percentage] of Object.entries(percentagesByDate)) {
        const dateString = this.formatDate(unformattedDateString);
        (delinquencyCategoryPercentagesByDate.hasOwnProperty(dateString)) || (delinquencyCategoryPercentagesByDate[dateString] = {});
        delinquencyCategoryPercentagesByDate[dateString][delinquencyCategory] = 100.0 * percentage;
      }
    }

    let chartData: ChartDatum[] = [];
    for (const [date, percentagesByDelinquencyCategory] of Object.entries(delinquencyCategoryPercentagesByDate)) {
      const entry = { name: date, ...percentagesByDelinquencyCategory };
      chartData.push(entry);
    }

    return chartData;
  }

  filterData(rawData: RawData) {
    if (Object.keys(this.cache.filteredData).length > 0) return this.cache.filteredData;

    delete rawData['Current'];
    this.cache.filteredData = rawData;
    return rawData;
  }

  formatDate(dateString: string): string {
    return formatShort(stringToDate(dateString));
  }

  delinquencyCategories(rawData: RawData): string[] {
    if (this.cache.delinquencyCategories.length > 0) return this.cache.delinquencyCategories;

    const unsortedDelinquencyCategories = Object.keys(rawData);
    const scrubRegex = /[^[0-9]+/;

    const sortedDelinquencyCategories = unsortedDelinquencyCategories.sort((a, b) => {
      const scrubbedA = a.replace(scrubRegex, '');
      const scrubbedB = b.replace(scrubRegex, '');

      const scrubbedAInt = parseInt(scrubbedA);
      const scrubbedBInt = parseInt(scrubbedB);

      if (isNaN(scrubbedAInt)) return 1;
      return scrubbedAInt > scrubbedBInt ? 1 : -1
    });

    this.cache.delinquencyCategories = sortedDelinquencyCategories;
    return sortedDelinquencyCategories;
  }

  yTickFormatter = (tick: any) => {
    return `${tick}`;
  }

  xTickFormatter = (tick: any) => {
    return `${tick}%`
  }

  tooltipFormatter = (value: number, name: string, props: any) => {
    return `${value.toFixed(2)}%`
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <HorizontalStackedBarChart
      xTickFormatter={this.xTickFormatter}
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={this.tooltipFormatter}
      tooltipContent={<TooltipContentWithTotal />}
      data={this.state.data}
      barDataKeys={this.state.dataKeys}
      labels={this.state.labels}
      barCategoryGap={2} />
  }
}

export default DelinquencyCategoryLoanCountPercentTrendChart;
