import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import StackedBarChart, { SeriesData, ChartLabels } from '../common/StackedBarChart';
import TooltipContentWithTotal from '../common/TooltipContentWithTotal';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError'

interface Props {
  valuation: { id: number};
}

interface State {
  data: SeriesData[];
  dataKeys: string[];
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

class RateShockDollarsChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'bps from Par Rate',
        yAxis: 'Value $' },
      loaded: false,
      error: null };
  }

  componentDidMount = () => {
    ApiService.get('/api/chart_data/rate_shocks/value_dollars', { valuation_id: this.props.valuation.id })
      .then((response: AxiosResponse) => {
        this.setState({
          data: this.convertToSeries(response.data),
          dataKeys: this.dataKeys(response.data),
          loaded: true
        });
      })
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  convertToSeries(rawData: Object) {
    let convertedObject = Object()
    let convertedData = Array()

    for (const [key, values] of Object.entries(rawData)) {
      let valueMap = new Map(Object.entries(values))
      for(let datum of Array.from(valueMap)) {
        if(!convertedObject[datum[0]]) {
          convertedObject[datum[0]] = Object()
          convertedObject[datum[0]][key] = datum[1]
        } else {
          convertedObject[datum[0]][key] = datum[1]
        }
      }
    }

    for(const [key, values] of Object.entries(convertedObject)) {
      let shock = {
        name: key
      }

      convertedData.push(Object.assign(shock, values))
    }

    return convertedData
  }

  dataKeys(rawData: Object): string[] {
    return Object.keys(rawData)
  }

  yTickFormatter = (tick: any) => {
    return `$${Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD' }).format(tick)}`;
  }

  tooltipFormatter = (val: any) => {
    return `$${Intl.NumberFormat('en-US', { notation: 'compact', currency: 'USD' }).format(val)}`;
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <StackedBarChart
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={this.tooltipFormatter}
      tooltipLabelFormatter={(bps) => bps + " bps"}
      tooltipContent={<TooltipContentWithTotal />}
      data={this.state.data}
      barDataKeys={this.state.dataKeys}
      labels={this.state.labels} />
  }
}

export default RateShockDollarsChart;
