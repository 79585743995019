import React, { PureComponent } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import * as phxColors from '../../../common/phxColors';
import { BaseAxisProps } from 'recharts/types/util/types';

interface Props {
  data: ChartDatum[];
  yTickFormatter: BaseAxisProps['tickFormatter'],
  tooltipFormatter: Function;
  labels: ChartLabels;
}

interface State {}

export interface ChartDatum {
  name: string;
  value: number;
  startingValue: number;
  total?: boolean;
}

export interface ChartLabels {
  xAxis: string;
  yAxis: string;
}

class CustomizedAxisTick extends PureComponent<{ x?: number, y?: number, stroke?: string, payload?: { value: any } }> {
  render() {
    const { x, y, stroke, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-35)">
          {payload?.value}
        </text>
      </g>
    );
  }
}

class WaterfallChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  xAxisLabel(): string {
    return (this.props.labels && this.props.labels.xAxis)
  }

  yAxisLabel(): string {
    return (this.props.labels && this.props.labels.yAxis)
  }

  render(){
    return(
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            label={{
              value: this.xAxisLabel(),
              position: 'bottom'
            }}
            tick={<CustomizedAxisTick />}
            interval={0}
            height={100}
          />
          <YAxis
            tickFormatter={this.props.yTickFormatter}
            label={{
              value: this.yAxisLabel(),
              angle: -90,
              position: 'left'
            }}
          />
          <Tooltip
            formatter={this.props.tooltipFormatter}
            wrapperStyle={{ zIndex: 1000 }}
          />
          <Bar dataKey="startingValue" stackId="a" fill="transparent" />
          <Bar dataKey="value" stackId="a" fill={phxColors.PHX_STEEL_BLUE}>
            {
              this.props.data.map((item, index) => {
                if (item.value < 0) return <Cell key={index} fill={phxColors.PHX_ROSE_RED} />;
                if (item.total == true) return <Cell key={index} fill={phxColors.PHX_FERN_GREEN} />;
                return <Cell key={index} fill={phxColors.PHX_STEEL_BLUE} />;
              })
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default WaterfallChart;
