export function stringToDate(d: string): Date {
  return new Date(d);
}

export function formatLocal(date: Date): string {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' } as const;
  return date.toLocaleString('en-US', options);
}

export function formatShort(date: Date): string {
  const monthOpts = { month: 'short' } as const;
  let result = date.toLocaleString('en-US', monthOpts);

  const yearOpts = { year: '2-digit' } as const;
  return result + ` '${date.toLocaleString('en-US', yearOpts)}`;
}
