import React, { ReactElement } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as phxColors from '../../../common/phxColors';
import { AxisInterval, BaseAxisProps } from 'recharts/types/util/types';

interface Props {
  data: ChartDatum[];
  barDataKeys: string[];
  tooltipFormatter: Function;
  tooltipContent?: ReactElement;
  labels: ChartLabels;
  containerWidth?: string | number;
  containerHeight?: string | number;
  barCategoryGap?: string | number; // Recharts ignores this if a barSize is set
  barSize?: number;
  xAxisAllowDataOverflow?: boolean;
  xTicks?: (string|number)[];
  xTickFormatter?: BaseAxisProps['tickFormatter'],
  xDomain?: Domain;
  xInterval?: AxisInterval;
  yTickFormatter?: BaseAxisProps['tickFormatter'];
  yInterval?: AxisInterval;
}

interface State {}

export interface ChartDatum {
  [id: string]: any;
}

export interface ChartLabels {
  xAxis: string;
  yAxis: string;
}

export interface SeriesDatum {
  [key: string]: number;
}

export interface SeriesData {
  name: string;
  [key: string]: any;
}

type Domain = [(string|number), (string|number)];

class HorizontalStackedBarChart extends React.PureComponent<Props, State> {
  defaultContainerWidth = '100%';
  defaultContainerHeight = '100%';

  constructor(props: Props) {
    super(props);
  }

  tooltipContent(): ReactElement | undefined {
    if (this.props.tooltipContent) return this.props.tooltipContent;
    return undefined;
  }

  containerWidth(): string | number {
    if (this.props.containerWidth != undefined) return this.props.containerWidth;
    return this.defaultContainerWidth;
  }

  containerHeight(): string | number {
    if (this.props.containerHeight != undefined) return this.props.containerHeight;
    return this.defaultContainerHeight;
  }

  xAxisLabel(): string {
    return (this.props.labels && this.props.labels.xAxis)
  }

  xAxisAllowDataOverflow(): boolean {
    return (this.props.xAxisAllowDataOverflow || false)
  }

  xTickFormatter(): BaseAxisProps['tickFormatter'] {
    if (this.props.xTickFormatter) return this.props.xTickFormatter;
    return (val: any) => val;
  }

  xTicks(): (string | number)[] | undefined {
    if (this.props.xTicks) return this.props.xTicks;
    return undefined;
  }

  xDomain(): Domain {
    if (this.props.xDomain) return this.props.xDomain;
    return [0, 'auto'];
  }

  xInterval(): AxisInterval | undefined {
    if (this.props.xInterval != undefined) return this.props.xInterval;
    return undefined;
  }

  yAxisLabel(): string {
    return (this.props.labels && this.props.labels.yAxis)
  }

  yTickFormatter(): BaseAxisProps['tickFormatter'] {
    if (this.props.yTickFormatter) return this.props.yTickFormatter;
    return (val: any) => val;
  }

  yInterval(): AxisInterval | undefined {
    if (this.props.yInterval != undefined) return this.props.yInterval;
    return undefined;
  }

  barCategoryGap(): string | number | undefined {
    if (this.props.barCategoryGap != undefined) return this.props.barCategoryGap;
    return undefined;
  }

  barSize(): number | undefined {
    if (this.props.barSize != undefined) return this.props.barSize;
    return undefined;
  }

  render(){
    return(
      <ResponsiveContainer width={this.containerWidth()} height={this.containerHeight()}>
        <BarChart
          data={this.props.data}
          margin={{
            top: 0,
            right: 20,
            left: 20,
            bottom: 10,
          }}
          layout='vertical'
          barCategoryGap={this.barCategoryGap()}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tickFormatter={this.xTickFormatter()}
            label={{
              value: this.xAxisLabel(),
              position: 'bottom'
            }}
            type='number'
            ticks={this.xTicks()}
            domain={this.xDomain()}
            interval={this.xInterval()}
            allowDataOverflow={this.xAxisAllowDataOverflow()}
          />
          <YAxis
            dataKey='name'
            tickFormatter={this.yTickFormatter()}
            label={{
              value: this.yAxisLabel(),
              angle: -90,
              position: 'left'
            }}
            type='category'
            interval={this.yInterval()}
          />
          <Tooltip
            formatter={this.props.tooltipFormatter}
            content={this.tooltipContent()}
            wrapperStyle={{ zIndex: 1000 }}
          />
          <Legend
            verticalAlign='bottom'
            align='center'
            layout='horizontal'
            wrapperStyle={{
              width: '100%',
              paddingTop: 30
            }}
          />
          {this.props.barDataKeys.map((s, i) => (
            <Bar
              dataKey={s}
              stackId="a"
              fill={phxColors.colorForIndex(i)}
              key={ Math.random().toString(36).substr(2, 9) }
              width={'100%'}
              barSize={this.barSize()}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default HorizontalStackedBarChart;
