import React, { ReactInstance, RefObject, SyntheticEvent } from 'react';

interface Props {
  error?: any
}

interface State {}

export class DataError extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  defaultError = () => {
    return (
      <div>
        <p>Encountered an error while retrieving data.</p>
        <p>Please try again later.</p>
      </div>
    );
  }

  render() {
    return (
      <div className='text-center'>
        {this.props.error != undefined ? this.props.error : this.defaultError() }
      </div>
    );
  }
}

export default DataError;
