import axios, { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import qs from 'qs';
import SeriesLineWithAreaChart from '../charts/common/SeriesLineWithAreaChart';
import { ChartDatum } from '../charts/common/SeriesLineChart';
import Spinner from '../shared/Spinner';
import DataError from '../shared/DataError'
import moment from 'moment/moment'

interface Props {
  animations_enabled: boolean;
  instruments: Instrument[]
  spreads: Spread[]
  data_date_start: string
  data_date_end: string
}

interface State {
  data: ChartDatum[]
  lineDataKeys: string[]
  areaDataKeys: string[]
  loaded: boolean
  error: any
}

interface Instrument {
  name: string
  remote_name: string
}

interface Spread {
  name: string
  instrument_1: Instrument
  instrument_2: Instrument
}

class MarketRateTimeSeriesChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      lineDataKeys: [],
      areaDataKeys: [],
      loaded: false,
      error: null
      };
  }

  componentDidMount = () => {
    axios.get(`/api/chart_data/market_rates/time_series`, {
      params: {
        instruments: this.props.instruments,
        spreads: this.props.spreads,
        data_date_start: this.props.data_date_start,
        data_date_end: this.props.data_date_end
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
    .then((response: AxiosResponse) => {
      this.setState({
        data: response.data.data,
        lineDataKeys: response.data.instruments,
        areaDataKeys: response.data.spreads,
        loaded: true })
    })
    .catch((error: AxiosError) => this.setState({ loaded: true, error: error }));
  }

  paramsSerializer = (params: any) => {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }

  xTickFormatter = (tick: any) => {
    return moment(tick, 'YYYY-MM-DD').format('L');
  }

  yTickFormatter = (tick: any) => {
    return `${tick.toFixed(3)}%`
  }

  tooltipLabelFormatter = (label: string) => {
    return moment(label, 'YYYY-MM-DD').format('L');
  }

  tooltipFormatter = (value: number, name: string, props: any) => {
    if (typeof value === "string") { return value; }
    return `${value.toFixed(3)}%`
  }

  render() {
    return this.state.loaded ? this.renderChart() : this.renderLoading();
  }

  private labels = () => {
    return { xAxis: undefined, yAxis: 'Mortgage Par Rate', yAxisSecondary: '10 year & 2 year treasury spread' };
  }

  private renderLoading = () => {
    return <Spinner width="3rem" height="3rem" />
  }

  private renderChart = () => {
    if (this.state.error) { return <DataError /> }
    return (
      <SeriesLineWithAreaChart
        id={"marketRatesChart"}
        xTickFormatter={this.xTickFormatter}
        yTickFormatter={this.yTickFormatter}
        tooltipLabelFormatter={this.tooltipLabelFormatter}
        tooltipFormatter={this.tooltipFormatter}
        data={this.state.data}
        lineDataKeys={this.state.lineDataKeys}
        areaDataKeys={this.state.areaDataKeys}
        labels={this.labels()}
        dots={false}
        animationsEnabled={this.props.animations_enabled}
        />);
  }
}

export default MarketRateTimeSeriesChart;
