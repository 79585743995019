import { colorForIndex } from '../../../common/phxColors';
import React, { ReactElement } from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { BaseAxisProps } from 'recharts/types/util/types';

interface Props {
  data: ChartDatum[];
  barDataKeys: string[];
  yTickFormatter: BaseAxisProps['tickFormatter'],
  tooltipFormatter: Function;
  tooltipContent?: ReactElement;
  labels: ChartLabels;
}

interface State {}

export interface ChartDatum {
  // Expect "name" for x-axis and other keys are y-axis values
  [key: string]: any
}

export interface ChartLabels {
  xAxis: string;
  yAxis: string;
}

class StackedAreaChart extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  tooltipContent(): ReactElement | undefined {
    if (this.props.tooltipContent) return this.props.tooltipContent;
    return undefined;
  }

  xAxisLabel(): string {
    return (this.props.labels && this.props.labels.xAxis)
  }

  yAxisLabel(): string {
    return (this.props.labels && this.props.labels.yAxis)
  }

  render(){
    return(
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <XAxis
            dataKey="name"
            label={{
              value: this.xAxisLabel(),
              position: 'bottom'
            }} />
          <YAxis
            tickFormatter={this.props.yTickFormatter}
            label={{
              value: this.yAxisLabel(),
              angle: -90,
              position: 'left'
            }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            formatter={this.props.tooltipFormatter}
            wrapperStyle={{ zIndex: 1000 }}
            content={this.tooltipContent()}
          />
          <Legend
            verticalAlign='bottom'
            align='center'
            layout='horizontal'
            wrapperStyle={{
              width: '100%',
              position: 'relative',
              paddingTop: 5
            }}
          />
          {this.props.barDataKeys.map((s, i) => (
            <Area type="monotone" stackId={1} dataKey={s} stroke={colorForIndex(i)} fillOpacity={0.85} fill={colorForIndex(i)} key={Math.random().toString(36).substr(2, 9)} />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default StackedAreaChart;
