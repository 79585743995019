import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import SeriesLineChart, { ChartDatum, ChartLabels } from '../common/SeriesLineChart';
import { ApiService } from '../../../services/ApiService';
import Spinner from '../../shared/Spinner';
import DataError from '../../shared/DataError'

interface Props {
  valuation: { id: number};
}
interface State {
  data: ChartDatum[]
  dataKeys: string[]
  labels: ChartLabels;
  loaded: boolean;
  error: any;
}

class RateShockBPSChart extends React.PureComponent<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      dataKeys: [],
      labels: {
        xAxis: 'bps from Par Rate',
        yAxis: 'Value %' },
      loaded: false,
      error: null };
  }

  componentDidMount = () => {
    ApiService.get(`/api/chart_data/rate_shocks/value_percents`, { valuation_id: this.props.valuation.id })
      .then((response: AxiosResponse) => {
        this.setState({
          data: response.data.data,
          dataKeys: response.data.note_types,
          loaded: true });
      })
      .catch((error: AxiosError) => this.setState({ loaded: true, error: error }))
  }

  yTickFormatter = (tick: any) => {
    return `${tick}%`;
  }

  tooltipFormatter = (val: any) => {
    return `${val.toFixed(2)}%`
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        { this.state.loaded ? this.renderChart() : this.renderLoading() }
      </div>
    );
  }

  private renderLoading = () => {
    return <Spinner width="6rem" height="6rem" />
  }

  private renderChart = () => {
    if (this.state.error)
      return <DataError />

    return <SeriesLineChart
      yTickFormatter={this.yTickFormatter}
      tooltipFormatter={this.tooltipFormatter}
      tooltipLabelFormatter={(bps) => bps + " bps"}
      data={this.state.data}
      lineDataKeys={this.state.dataKeys}
      labels={this.state.labels} />
  }
}

export default RateShockBPSChart;
